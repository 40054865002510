/*= input focus effects css
=========================== */
:focus {
    outline: none;
}

.input-effect {
    margin: 40px 0px;
    position: relative;
}
    /* necessary to give position: relative to parent. */
    .input-effect input, .input-effect textarea {
        font: 15px/24px "Lato", Arial, sans-serif;
        color: #333;
        width: 100%;
        box-sizing: border-box;
        letter-spacing: 1px;
        position: relative;
    }

    .input-effect textarea {
        min-height: 120px;
        width: 100%;
        resize: vertical;
    }

.effect-19,
.effect-20,
.effect-21 {
    border: 1px solid #ccc;
    padding: 7px 14px;
    transition: 0.4s;
    background: transparent;
}

    .effect-19 ~ .focus-border:before,
    .effect-19 ~ .focus-border:after {
        content: "";
        position: absolute;
        top: -1px;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: #3399FF;
        transition: 0.4s;
    }

    .effect-19 ~ .focus-border:after {
        top: auto;
        bottom: 0;
    }

    .effect-19 ~ .focus-border i:before,
    .effect-19 ~ .focus-border i:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 2px;
        height: 0;
        background-color: #3399FF;
        transition: 0.6s;
    }

    .effect-19 ~ .focus-border i:after {
        left: auto;
        right: 0;
    }

    .effect-19:focus ~ .focus-border:before,
    .effect-19:focus ~ .focus-border:after,
    .has-content.effect-19 ~ .focus-border:before,
    .has-content.effect-19 ~ .focus-border:after {
        left: 0;
        width: 100%;
        transition: 0.4s;
    }

    .effect-19:focus ~ .focus-border i:before,
    .effect-19:focus ~ .focus-border i:after,
    .has-content.effect-19 ~ .focus-border i:before,
    .has-content.effect-19 ~ .focus-border i:after {
        top: -1px;
        height: 100%;
        transition: 0.6s;
    }

.input-effect label {
    position: absolute;
    left: 14px;
    width: 100%;
    top: 10px;
    color: #aaa;
    transition: 0.3s;
    letter-spacing: 0.5px;
}

    .input-effect label.focused {
        top: -18px;
        left: 0;
        font-size: 12px;
        color: #3399FF;
        transition: 0.3s;
    }

/*= input focus effects css End
=============================== */
