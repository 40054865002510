
.panel-content .row {
    min-height: 40px;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    border-left: 2px solid transparent;
}

.panel-content img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    box-shadow: 2px 2px 20px 0px #dcdcdc;
}

.panel-content .col i {
    height: 40px;
    width: 40px;
    color: #fff;
    border-radius: 50%;
    line-height: 40px;
    background-color: #949494;
}

.panel-content .row img {
    margin: 0 15px 0 0;
    vertical-align: middle;
}

.panel-content .row .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.panel-content .row .about-place,
.panel-content .row .more-information,
.panel-content .col .more-information,
.panel-content .hobby .more-information {
    display: none;
}

.panel-content h3 {
    font-size: 14px;
    padding: 0;
    margin: 10px;
    font-family: Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
    font-display: swap;
}

.panel-content .row h3 {
    display: inline;
    margin: 0 5px 0 0;
}

.panel-content .row.current h3 {
    color: #007eff;
}

.panel-content .row .place {
    color: #a8a8a8;
    font-size: 11px;
}

    .panel-content .row .place a {
        text-decoration: none;
        color: inherit;
        font-weight: bold;
    }

        .panel-content .row .place a:hover {
            color: #848484;
        }

.panel-content .more {
    font-size: 11px;
    color: #a8a8a8;
}

.panel-content .row:hover,
.panel-content .col:hover,
.panel-content .row.active,
.panel-content .col.active {
    background-color: #f3f3f3 !important;
}

.panel-content .row.highlighted,
.panel-content .col.highlighted {
    animation-name: highlight;
    animation-duration: 5s;
}

.panel-content .row.current {
    background-color: #f7f9ff;
    border-left: 2px solid #007eff;
}

@keyframes highlight {
    50% {
        background-color: #fcffd7;
    }
}

.panel-content .col {
    text-align: center;
    cursor: pointer;
}

.panel-content .col-3 {
    float: left;
    width: 33%;
}

.panel-content .col-4 {
    float: left;
    width: 25%;
}
.panel-content .col-2 {
    float: left;
    width: 50%;
}

.panel-content .col-1 {
    width: 100%;
}
