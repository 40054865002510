.badges.highlight {
    margin: 20px auto;
}

.badges.programming .badge {
    border-bottom: 2px solid #65a4dd;
}

.badges.system .badge {
    border-bottom: 2px solid #cfc925;
}

.badges.software .badge {
    border-bottom: 2px solid #eb7474;
}

.badge-container {
    display: inline-flex;
    box-sizing: border-box;
}

    .badge-container.highlight:nth-child(2) {
        padding: 0px 0px 0px 20%;
    }

    .badge-container.highlight:nth-child(3) {
        padding: 0px 20% 0px 0px;
    }

.badge {
    display: inline-block;
    padding: 0px 0px 0 10px;
    font-size: 12px;
    height: 25px;
    line-height: 25px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    margin: 5px;
    color: #000;
    font-weight: bold;
    font-family: 'Roboto Condensed';
    font-display: swap;
}

.badge-container.highlight {
    text-align: left;
}

    .badge-container.highlight .badge {
        padding: 0px 0px 0 15px;
        height: 50px;
        line-height: 50px;
        margin: 10px auto;
        font-size: 20px;
        box-shadow: 2px 2px 20px 0px #dcdcdc;
        color: #fff;
        background-color: #0d3458;
    }

    .badge .score {
        display: inline-block;
        margin: 0 5px;
    }

    .badge .level {
        font-size: 7px;
        position: relative;
        bottom: 2px;
        margin: 0 2px;
        border-radius: 50%;
        border-bottom: 1px solid #65a4dd;
    }

        .badge .level:first-child {
            color: #a6eaff;
        }

        .badge .level:nth-child(2) {
            color: #759ccd;
        }

        .badge .level:nth-child(3) {
            color: #6e7eab;
        }

.badge-container.highlight .badge .score {
    margin: 0 10px;
}

.badge-container.highlight .badge .level {
    bottom: 4px;
}