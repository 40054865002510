
.hobby {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 20%;
}

    .hobby img {
        width: 100px;
        height: 100px;
        opacity: 1;
        transition: .5s ease;
        backface-visibility: hidden;
    }

    .hobby:hover img, .hobby.active img {
        opacity: 0.3;
    }

    .hobby:hover .middle, .hobby.active .middle {
        opacity: 1;
    }

    .hobby.highlighted img {
        filter: opacity(0.75);
    }

    .hobby .hobby-container {
        padding: 4px;
        box-sizing: border-box;
    }