
#panel-information {
    z-index: 1;
    font-size: 13px;
    display: none;
}

    #panel-information .header {
        margin: 0 0 20px 0;
        font-size: 20px;
        height: 60px;
    }

    #panel-information .header > div {
        display: table-cell;
    }

    #panel-information .img {
        float: left;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: #949494;
        color: #fff;
        line-height: 60px;
        text-align: center;
    }

        #panel-information .img.programming {
            background-color: #65a4dd;
        }

        #panel-information .img.system {
            background-color: #cfc925;
        }

        #panel-information .img.software {
            background-color: #eb7474;
        }

        #panel-information .title {
            display: table-cell;
            vertical-align: middle;
        }

    #panel-information .information-line {
        margin: 10px 0px;
    }

        #panel-information .information-line label {
            font-weight: bold;
        }

.close-button {
    float: right;
    cursor: pointer;
    color: #7a7a7a;
    width: 15px;
    height: 15px;
}

.closable .fa-xmark {
    margin: auto;
}
