.contrast:not(.dark) #left-panel h2 {
    color: #65676B;
}

.contrast:not(.dark) #left-panel .panel-content span.active {
    color: #65676B;
}

.contrast:not(.dark) span.country-indicator {
    color: #65676B;
}

.contrast:not(.dark) #menu {
    border-left-color: #65676B;
    color: #65676B;
}

.contrast:not(.dark) footer {
    color: #65676B;
}

.contrast:not(.dark) .panel-content .row .place {
    color: #65676B;
}

.contrast:not(.dark) .panel-content .more {
    color: #65676B;
}

.contrast:not(.dark) .panel-content .more {
    color: #65676B;
}

.contrast:not(.dark) .lighthouse-summary .title {
    color: #65676B;
  }
  
.contrast:not(.dark) .lighthouse-summary .source {
    color: #65676B;
  }