
#menu {
    margin: 30px auto;
    list-style-type: none;
    padding: 0;
    border-left: 1px solid #999;
    font-size: 15px;
    color: #999;
    text-shadow: 1px 1px #f5f5f5;
}

    #menu a:hover {
        text-decoration: none;
        font-weight: bold;
    }

    #menu a.active {
        font-weight: bold;
    }

    #menu li::before {
        content: '●';
        margin: 0 5px 0 0;
    }

    #menu li {
        margin-left: -5px;
        padding: 5px 0px;
    }

    #menu ul {
        list-style-type: none;
        padding: 5px 0 0 0;
        margin-left: 5px;
        font-size: 12px;
        font-weight: normal;
    }

        #menu ul li::before {
            content: '-';
            margin: 0 20px 0 0;
        }

        #menu ul li {
            margin: 0;
            padding: 3px 0px;
        }

@media only screen and (max-width: 720px) {
    .menu a {
        width: 50px !important;
        height: 50px !important;
    }

        .menu a .svg-inline--fa {
            font-size: 20px !important;
            padding: 15px !important;
        }
}