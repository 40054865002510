#App {
    display: flex;
    flex-direction: column;
}

.transparent {
    background-color: transparent;
    border-color: transparent;
}

.transparent #header {
    display: none;
}

.error-404 {
    position: absolute;
    top: 0;
    text-align: center;
    height: 100%; 
    width: 100%; 
    background-image: url('./images/john-travolta.gif');
    background-position: top center;
    background-size: cover;
}

.error-404 div {
    padding: 0 20px;
}

.error-404 h2 {
    font-family: 'Press Start 2P', monospace;
    font-size: 11vw;
    color: #fff;
    padding: 100px 0 50px;
    margin-top: 100px;
    opacity: 0.9;
}

.error-404 p {
    font-family: 'Press Start 2P', monospace;
    font-size: 16px;
    color: #fff;
}

.error-404 p strong {
    font-size: 25px;
}

#bricksCanvas {
    position: absolute;
    top: 0;
    padding: 0;
    background: transparent;
    display: block;
    margin: 0 auto;
    height: 100%; 
    width: 100%; 
    opacity: 0.3;
}

#bricksCanvas.inactive {
    opacity: 0;
}

#bricksCanvas.active {
    opacity: 1;
}