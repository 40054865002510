
#footer-mobile {
    display: none;
}

@media only screen and (max-width: 1100px) {
    #footer-mobile {
        display: block !important;
    }
}
