body.dark {
    background-color: #000;
    color: #fff;
}

.dark header {
    background-color: #eccaff;
    border-bottom-color: #3a3a3a;
}

.dark #picture {
    box-shadow: 0px 0px 80px 7px #000;
}

.dark header a {
    color: #000;
}

.dark #left-panel h2 {
    color: #cdcdcd;
}

.dark #left-panel .panel-content span.active {
    color: #cdcdcd;
}

.dark span.country-indicator {
    color: #cdcdcd;
}


.dark #menu {
    border-left-color: #cdcdcd;
    color: #cdcdcd;
}

.dark footer {
    color: #cdcdcd;
}

.dark .panel-content .row .place {
    color: #cdcdcd;
}

.dark .panel-content .more {
    color: #cdcdcd;
}

.dark .panel-content .more {
    color: #cdcdcd;
}

.dark .box-content {
    background-color: #181818;
    border: 1px solid #343434;
    border-bottom: 1px solid #767676;
}

.dark .panel-content img {
    box-shadow: none;
}

.dark .panel-content .row:hover, .dark .panel-content .col:hover, .dark .panel-content .row.active, .dark .panel-content .col.active {
    background-color: #424242 !important;
}

.dark hr {
    border-top: 1px solid #343434;
}

.dark #menu, .dark footer {
    text-shadow: none;
}

.dark .badge-container .badge {
    border-top-color: #505050;
    border-left-color: #505050;
    border-right-color: #505050;
    background-color: #000000;
    color: #fff;
}

.dark .badge-container.highlight .badge {
    box-shadow: none;
    background-color: #f4e3ff;
    color: #000;
}

.dark .modal-content {
    background-color: #181818;
}

.dark .type-dark.react-tooltip.place-bottom::after,
.dark .type-dark.react-tooltip.place-top::after {
    border-top-color: #000;
    border-bottom-color: #000;
}

.dark #left-panel .settings button > .svg-inline--fa {
    color: #cdcdcd;
}

.dark #left-panel .settings.contrast .active > .svg-inline--fa, .dark #left-panel .settings button:hover > .svg-inline--fa {
    color: #fff;
}

.dark .text {
    color: #fff;
}

.dark #main-panel h2 .svg-inline--fa {
    color: #fff;
    border-right-color: #fff;
}

.dark .panel-content .row.current {
    background-color: #3a2f36;
    border-left: 2px solid #ffffff;
}

.dark .panel-content .row.current h3 {
    color: #fff;
}

.dark .input-effect input, .dark .input-effect textarea {
    color: #fff;
}

.dark .box-content.error {
    background-color: #ab0000;
    border: 1px solid #e36464;
    color: #fff;
}

.dark .lh-scores-header a {
    color: #0c6;
}

.dark .lh-scores-header a .lh-gauge__label {
    color: #fff;
}

.dark .lighthouse-summary .title {
    color: #cdcdcd;
}
  
.dark .lighthouse-summary .source {
    color: #cdcdcd;
}

.dark #hello .tooltip-inner {
    color: #fff;
    background-color: #000;
    box-shadow: none;
}
  
.dark #hello.bs-tooltip-top .tooltip-arrow::before, .dark #hello.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: #000;
}
  
.dark #hello.bs-tooltip-end .tooltip-arrow::before, .dark #hello.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: #000;
}
  
.dark #hello.bs-tooltip-bottom .tooltip-arrow::before, .dark #hello.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-top-color: #000;
}
  
.dark #hello.bs-tooltip-start .tooltip-arrow::before, .dark #hello.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-right-color: #000;
}