@charset "utf-8";


body, html, #root, #App {
    height: 100%
}

body {
    width: 100%;
    background-color: #e9ebee;
    font-family: 'Roboto Condensed', 'Calibri', sans-serif;
    margin: 0;
    font-display: swap;
}

h2 {
    margin: 0 0 20px 0;
}

hr {
    margin: 15px 0px;
    border: none;
    border-top: 1px solid #dedede;
}

a {
    text-decoration: none;
    color: inherit;
}

    a:hover {
        text-decoration: underline;
    }

button {
    cursor: pointer;
}

    button .svg-inline--fa {
        margin-right: 10px;
        font-size: 15px;
    }

.loading {
    color: #727b84;
}

.success {
    color: #54c15d;
}

    .success i, .fail .svg-inline--fa{
        margin-right: 3px;
    }

.fail {
    color: #ff0000;
}

#main-panel h2 {
    font-family: 'Roboto', 'Calibri', sans-serif;
    font-size: 25px;
    font-weight: 100;
    font-display: swap;
}

    #main-panel h2 .svg-inline--fa {
        font-size: 10px;
        padding-right: 5px;
        margin-right: 10px;
        color: #455064;
        vertical-align: middle;
        position: relative;
        bottom: 2px;
        border-right: 3px solid #455064;
    }


#container {
    margin: 100px 0 0 0;
    height: 100%;
}

h1 {
    margin: 0 0 0 100px;
    height: 100%;
    color: #fff;
    line-height: 100px;
    display: inline-block;
    font-family: 'Oswald', 'Calibri', sans-serif;
    text-transform: uppercase;
    font-size: 2em;
    font-display: swap;
}

.content {
    margin: 0px auto;
    max-width: 1200px;
    height: 100%;
}

.first-box-content span {
    margin-left: 76px;
    font-style: italic;
}

#main-panel {
    width: 50%;
    padding-left: 320px;
}

.box-content {
    background-color: #fff;
    margin: 20px 0px;
    padding: 20px;
    border-radius: 3px;
    border: 1px solid #ddd;
    border-bottom: 1px solid #bbb;
}

    .box-content.error {
        background-color: #ffeded;
        border: 1px solid #e36464;
        border-bottom: 1px solid #ab0000;
        color: #ab0000;
        padding: 5px 10px;
    }

    .box-content hr:last-child {
        display: none;
    }

#right-panel {
    width: 260px;
    position: fixed;
    padding-right: 0px;
}

.column {
    display: inline-block;
    padding: 0px 10px;
}

.clear {
    clear: both;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
}

.text {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    padding: 16px 32px;
}

.btn {
    cursor: pointer;
}

footer, footer-mobile {
    font-size: 13px;
}

#contact-form .attachment-box {
    margin-top: 5px;
    border: 1px dashed #ccc;
}

    #contact-form .attachment-box > div {
        padding: 10px;
    }

.hidden {
    display: none !important;
}

label.regular {
    width: 100%;
    color: #aaa;
    transition: 0.3s;
    letter-spacing: 0.5px;
}

    label.regular .svg-inline--fa {
        cursor: pointer;
        margin: 5px;
    }

        label.regular i:hover {
            color: #686868;
        }

.template-upload, .template-download {
    font-size: 12px;
    color: #686868;
}

@media only screen and (max-width: 1100px) {
    #right-panel {
        display: none;
    }

    #main-panel {
        width: 100%;
        box-sizing: border-box;
        padding-right: 0px;
    }

    .content {
        margin: 0 20px;
    }

    #left-panel a.contact-info {
        line-height: 40px;
    }

    #left-panel .settings.theme button, #left-panel .settings.contrast button {
        padding: 0px 5px;
    }
}

@media only screen and (max-width: 850px) {
    .col {
        width: 100% !important;
    }

    .col-2, .col-3 {
        float: none !important;
    }

    .box-content hr {
        display: block !important;
    }

        .box-content hr:last-child {
            display: none !important;
        }

    .badge-container.highlight {
        padding: 0px 0px 0px 0px !important;
        width: 100% !important;
    }

    .panel-content .row h3 {
        display: block;
    }
}

@media only screen and (max-width: 720px) {
    #container {
        margin-top: 0px;
    }

    #main-panel {
        position: relative;
        margin: 0px;
        padding: 0px;
        width: 100%;
    }

    #left-panel {
        height: auto;
        overflow-y: auto;
    }

    .no-mobile {
        display: none !important;
    }
}

/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0
}

    .modal.fade .modal-dialog {
        transition: -webkit-transform .3s ease-out;
        transition: transform .3s ease-out;
        transition: transform .3s ease-out,-webkit-transform .3s ease-out;
        -webkit-transform: translate(0,-25%);
        transform: translate(0,-25%)
    }

    .modal.show .modal-dialog {
        -webkit-transform: translate(0,0);
        transform: translate(0,0)
    }

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

    .modal-backdrop.fade {
        opacity: 0
    }

    .modal-backdrop.show {
        opacity: .5
    }

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #e9ecef
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0px 15px 15px 15px;
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid #e9ecef
}

    .modal-footer > :not(:first-child) {
        margin-left: .25rem
    }

    .modal-footer > :not(:last-child) {
        margin-right: .25rem
    }

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 30px auto
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px
    }
}



.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: .25rem;
    transition: all .15s ease-in-out
}

    .btn:focus, .btn:hover {
        text-decoration: none
    }

    .btn.focus, .btn:focus {
        outline: 0;
        box-shadow: 0 0 0 3px rgba(0,123,255,.25)
    }

    .btn.disabled, .btn:disabled {
        opacity: .65
    }

    .btn.active, .btn:active {
        background-image: none
    }

a.btn.disabled, fieldset[disabled] a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

    .btn-primary:hover {
        color: #fff;
        background-color: #0069d9;
        border-color: #0062cc
    }

    .btn-primary.focus, .btn-primary:focus {
        box-shadow: 0 0 0 3px rgba(0,123,255,.5)
    }

    .btn-primary.disabled, .btn-primary:disabled {
        background-color: #007bff;
        border-color: #007bff
    }

    .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
        background-color: #0069d9;
        background-image: none;
        border-color: #0062cc
    }

.btn-secondary {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96
}

    .btn-secondary:hover {
        color: #fff;
        background-color: #727b84;
        border-color: #6c757d
    }

    .btn-secondary.focus, .btn-secondary:focus {
        box-shadow: 0 0 0 3px rgba(134,142,150,.5)
    }

    .btn-secondary.disabled, .btn-secondary:disabled {
        background-color: #868e96;
        border-color: #868e96
    }

    .btn-secondary.active, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
        background-color: #727b84;
        background-image: none;
        border-color: #6c757d
    }

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

    .btn-success:hover {
        color: #fff;
        background-color: #218838;
        border-color: #1e7e34
    }

    .btn-success.focus, .btn-success:focus {
        box-shadow: 0 0 0 3px rgba(40,167,69,.5)
    }

    .btn-success.disabled, .btn-success:disabled {
        background-color: #28a745;
        border-color: #28a745
    }

    .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
        background-color: #218838;
        background-image: none;
        border-color: #1e7e34
    }

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

    .btn-info:hover {
        color: #fff;
        background-color: #138496;
        border-color: #117a8b
    }

    .btn-info.focus, .btn-info:focus {
        box-shadow: 0 0 0 3px rgba(23,162,184,.5)
    }

    .btn-info.disabled, .btn-info:disabled {
        background-color: #17a2b8;
        border-color: #17a2b8
    }

    .btn-info.active, .btn-info:active, .show > .btn-info.dropdown-toggle {
        background-color: #138496;
        background-image: none;
        border-color: #117a8b
    }

.btn-warning {
    color: #111;
    background-color: #ffc107;
    border-color: #ffc107
}

    .btn-warning:hover {
        color: #111;
        background-color: #e0a800;
        border-color: #d39e00
    }

    .btn-warning.focus, .btn-warning:focus {
        box-shadow: 0 0 0 3px rgba(255,193,7,.5)
    }

    .btn-warning.disabled, .btn-warning:disabled {
        background-color: #ffc107;
        border-color: #ffc107
    }

    .btn-warning.active, .btn-warning:active, .show > .btn-warning.dropdown-toggle {
        background-color: #e0a800;
        background-image: none;
        border-color: #d39e00
    }

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

    .btn-danger:hover {
        color: #fff;
        background-color: #c82333;
        border-color: #bd2130
    }

    .btn-danger.focus, .btn-danger:focus {
        box-shadow: 0 0 0 3px rgba(220,53,69,.5)
    }

    .btn-danger.disabled, .btn-danger:disabled {
        background-color: #dc3545;
        border-color: #dc3545
    }

    .btn-danger.active, .btn-danger:active, .show > .btn-danger.dropdown-toggle {
        background-color: #c82333;
        background-image: none;
        border-color: #bd2130
    }

.btn-light {
    color: #111;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

    .btn-light:hover {
        color: #111;
        background-color: #e2e6ea;
        border-color: #dae0e5
    }

    .btn-light.focus, .btn-light:focus {
        box-shadow: 0 0 0 3px rgba(248,249,250,.5)
    }

    .btn-light.disabled, .btn-light:disabled {
        background-color: #f8f9fa;
        border-color: #f8f9fa
    }

    .btn-light.active, .btn-light:active, .show > .btn-light.dropdown-toggle {
        background-color: #e2e6ea;
        background-image: none;
        border-color: #dae0e5
    }

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

    .btn-dark:hover {
        color: #fff;
        background-color: #23272b;
        border-color: #1d2124
    }

    .btn-dark.focus, .btn-dark:focus {
        box-shadow: 0 0 0 3px rgba(52,58,64,.5)
    }

    .btn-dark.disabled, .btn-dark:disabled {
        background-color: #343a40;
        border-color: #343a40
    }

    .btn-dark.active, .btn-dark:active, .show > .btn-dark.dropdown-toggle {
        background-color: #23272b;
        background-image: none;
        border-color: #1d2124
    }

.btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff
}

    .btn-outline-primary:hover {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff
    }

    .btn-outline-primary.focus, .btn-outline-primary:focus {
        box-shadow: 0 0 0 3px rgba(0,123,255,.5)
    }

    .btn-outline-primary.disabled, .btn-outline-primary:disabled {
        color: #007bff;
        background-color: transparent
    }

    .btn-outline-primary.active, .btn-outline-primary:active, .show > .btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff
    }

.btn-outline-secondary {
    color: #868e96;
    background-color: transparent;
    background-image: none;
    border-color: #868e96
}

    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #868e96;
        border-color: #868e96
    }

    .btn-outline-secondary.focus, .btn-outline-secondary:focus {
        box-shadow: 0 0 0 3px rgba(134,142,150,.5)
    }

    .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
        color: #868e96;
        background-color: transparent
    }

    .btn-outline-secondary.active, .btn-outline-secondary:active, .show > .btn-outline-secondary.dropdown-toggle {
        color: #fff;
        background-color: #868e96;
        border-color: #868e96
    }

.btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745
}

    .btn-outline-success:hover {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745
    }

    .btn-outline-success.focus, .btn-outline-success:focus {
        box-shadow: 0 0 0 3px rgba(40,167,69,.5)
    }

    .btn-outline-success.disabled, .btn-outline-success:disabled {
        color: #28a745;
        background-color: transparent
    }

    .btn-outline-success.active, .btn-outline-success:active, .show > .btn-outline-success.dropdown-toggle {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745
    }

.btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8
}

    .btn-outline-info:hover {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8
    }

    .btn-outline-info.focus, .btn-outline-info:focus {
        box-shadow: 0 0 0 3px rgba(23,162,184,.5)
    }

    .btn-outline-info.disabled, .btn-outline-info:disabled {
        color: #17a2b8;
        background-color: transparent
    }

    .btn-outline-info.active, .btn-outline-info:active, .show > .btn-outline-info.dropdown-toggle {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8
    }

.btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107
}

    .btn-outline-warning:hover {
        color: #fff;
        background-color: #ffc107;
        border-color: #ffc107
    }

    .btn-outline-warning.focus, .btn-outline-warning:focus {
        box-shadow: 0 0 0 3px rgba(255,193,7,.5)
    }

    .btn-outline-warning.disabled, .btn-outline-warning:disabled {
        color: #ffc107;
        background-color: transparent
    }

    .btn-outline-warning.active, .btn-outline-warning:active, .show > .btn-outline-warning.dropdown-toggle {
        color: #fff;
        background-color: #ffc107;
        border-color: #ffc107
    }

.btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545
}

    .btn-outline-danger:hover {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545
    }

    .btn-outline-danger.focus, .btn-outline-danger:focus {
        box-shadow: 0 0 0 3px rgba(220,53,69,.5)
    }

    .btn-outline-danger.disabled, .btn-outline-danger:disabled {
        color: #dc3545;
        background-color: transparent
    }

    .btn-outline-danger.active, .btn-outline-danger:active, .show > .btn-outline-danger.dropdown-toggle {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545
    }

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa
}

    .btn-outline-light:hover {
        color: #fff;
        background-color: #f8f9fa;
        border-color: #f8f9fa
    }

    .btn-outline-light.focus, .btn-outline-light:focus {
        box-shadow: 0 0 0 3px rgba(248,249,250,.5)
    }

    .btn-outline-light.disabled, .btn-outline-light:disabled {
        color: #f8f9fa;
        background-color: transparent
    }

    .btn-outline-light.active, .btn-outline-light:active, .show > .btn-outline-light.dropdown-toggle {
        color: #fff;
        background-color: #f8f9fa;
        border-color: #f8f9fa
    }

.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40
}

    .btn-outline-dark:hover {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40
    }

    .btn-outline-dark.focus, .btn-outline-dark:focus {
        box-shadow: 0 0 0 3px rgba(52,58,64,.5)
    }

    .btn-outline-dark.disabled, .btn-outline-dark:disabled {
        color: #343a40;
        background-color: transparent
    }

    .btn-outline-dark.active, .btn-outline-dark:active, .show > .btn-outline-dark.dropdown-toggle {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40
    }

.btn-link {
    font-weight: 400;
    color: #007bff;
    border-radius: 0
}

    .btn-link, .btn-link.active, .btn-link:active, .btn-link:disabled {
        background-color: transparent
    }

        .btn-link, .btn-link:active, .btn-link:focus {
            border-color: transparent;
            box-shadow: none
        }

            .btn-link:hover {
                border-color: transparent
            }

            .btn-link:focus, .btn-link:hover {
                color: #0056b3;
                text-decoration: underline;
                background-color: transparent
            }

            .btn-link:disabled {
                color: #868e96
            }

                .btn-link:disabled:focus, .btn-link:disabled:hover {
                    text-decoration: none
                }

.btn-group-lg > .btn, .btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-group-sm > .btn, .btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

    .btn-block + .btn-block {
        margin-top: .5rem
    }

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
    width: 100%
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

    .close:focus, .close:hover {
        color: #000;
        text-decoration: none;
        opacity: .75
    }

button.close {
    padding: 0;
    background: 0 0;
    border: 0;
    -webkit-appearance: none
}

div .react-ripples {
    width: 100%;
    display: block !important;
}

