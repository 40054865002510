header {
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    background-color: #1860a1;
    border-bottom: 1px solid #004584;
    z-index: 1;
    overflow: visible !important;
}

    header .menu {
        float: right;
        height: 100%;
    }

    header a {
        display: block;
        height: 100%;
        width: 100px;
        color: #fff;
        margin: 0px;
        float: left;
    }

    header h1 a {
        width: auto;
        padding: 0px 10px;
    }

    header a:hover {
        background-color: rgba(0,0,0,0.3);
        text-decoration: none;
    }

    header .menu a .svg-inline--fa {
        font-size: 30px;
        padding: 35px;
    }

    header .content {
        position: relative;
    }


#picture {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 15px;
    position: fixed;
    box-shadow: 0px 0px 80px 7px #fff;
}

#header {
    width: 100%;
    height: 100%;
    top: 0px;
    position: absolute;
}

@media only screen and (max-width: 720px) {
    header {
        height: 50px !important;
    }

    h1 {
        line-height: 50px !important;
    }
}

@media only screen and (max-width: 460px) {
    h1 {
        font-size: 1.25em !important;
    }

        h1 a {
            padding: 0 !important;
        }

    .content {
        margin: 0 auto !important
    }
}
