
#left-panel .panel-content {
    font-size: 13px;
    line-height: 20px;
}

    #left-panel .panel-content p {
        margin: 0;
    }

    #left-panel .panel-content .active {
        color: #7a7a7a;
    }

.country-indicator {
    color: #7a7a7a;
}

#left-panel h2 {
    font-weight: bold;
    color: #888;
    font-size: 13px;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', 'Calibri', sans-serif;
    font-display: swap;
}

    #left-panel h2 .svg-inline--fa {
        margin: 0 5px 0 0;
        border: 1px solid;
        padding: 2px;
        border-radius: 5px;
        position: relative;
        top: 3px;
    }

#left-panel .panel-content .svg-inline--fa {
    width: 10px;
    text-align: center;
    margin: 0 10px 0 0;
    color: #7a7a7a;
}

#left-panel {
    width: 300px;
    position: fixed;
    top: 18px;
    padding-left: 0px;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

    #left-panel::-webkit-scrollbar {
        display: none;
    }

    #left-panel .settings {
        display: inline-block;
    }

        #left-panel .settings.theme, #left-panel .settings.contrast {
            float: right;
            margin-left: 10px;
        }

            #left-panel .settings.theme button, #left-panel .settings.contrast button {
                border: none;
                padding: 0;
                background-color: transparent;
            }

                #left-panel .settings.theme button .svg-inline--fa, #left-panel .settings.contrast button .svg-inline--fa {
                    width: auto;
                    margin: auto;
                }

                #left-panel .settings.contrast .active > .svg-inline--fa, #left-panel .settings button:hover > .svg-inline--fa {
                    color: #000;
                }

.blurred {
    filter: blur(3px);
}

@media only screen and (max-width: 720px) {
    #left-panel {
        position: relative;
        margin: 0px;
        padding: 0px;
        width: 100%;
    }
}
